import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Landing from './Landing';
import AboutMe from './AboutMe';
import Projects from './Projects';
import Experience from './Experience';
import Contact from './Contact';

const PagesV2 = ({ user }) => {
	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<Landing user = {user} />
				</Route>
				<Route path = "/aboutMe">
					<AboutMe user = {user} />
				</Route>
				<Route path = "/projects">
					<Projects user = {user} />
				</Route>
				<Route path = "/experience">
					<Experience user = {user} />
				</Route>
				<Route path = "/contact">
					<Contact user = {user} />
				</Route>
			</Switch>
		</Router>
	);
};

export default PagesV2;