import React from "react";
import './styles.css';
import Sidebar from '../../components/Sidebar';
import GithubIcon from '../../assets/githubIcon.png';
import LinkedInIcon from '../../assets/linkedInIcon.png';
import LandingProfile from '../../assets/landingProfile.jpg';
import tachyons from 'tachyons';

const Landing = ({user}) => {
	return (
		<div className = "LandingPage">
			<Sidebar color = "#7a77b9"/>
			<div className = "LandingPageContainer">
				<div className = "FirstColumn">
					<div className = "IntroName tc">
						<p className = "Introduction">hi, my name is</p>
						<h1 className = "NameHeader">Celine</h1>
					</div>
					<div className = "IconContainer pt3">
						<a href = {'https://github.com/cefong'} target = {'_blank'} className = "mr4">
							<div className = "grow dib">
									<img src = {GithubIcon} alt = {'Github Icon'} width = "24" height = "24" className = "Icon dib"/>
									<p className = "User pacifico dib">cefong</p>
							</div>
						</a>
						<a href = {'https://www.linkedin.com/in/cefong'} target = {'_blank'} className = "ml4">
							<div className = "grow dib">
								<img src = {LinkedInIcon} alt = {'LinkedIn Icon'} width = "24" height = "24" className = "Icon dib"/>
								<p className = "User pacifico dib">cefong</p>
							</div>
						</a>
					</div>
				</div>
				<img src = {LandingProfile} alt = {"Celine's Profile Picture"} className = "LandingProfile"/>
			</div>
		</div>
	);
};

export default Landing;