import React from "react";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import './styles.css';

const Contact = ({user}) => {
	return (
		<div className = "Contact">
			<Sidebar color = "#7a77b9"/>
			<iframe className= "Form" src="https://www.powr.io/form-builder/u/90c2d1b9_1591476643#platform=iframe">
			</iframe>
		</div>
	);
};

export default Contact;

