import React from "react";
import Sidebar from "../../components/Sidebar";
import tachyons from 'tachyons';
import Footer from "../../components/Footer";
import './styles.css';

const Experience = ({user}) => {
	return (
		<div className = "Experience">
			<Sidebar />
			<div className = "ExperiencesContainer">
				<p className = "pacifico ExperiencesHeader">experience</p>
				<div className = "TileHolder">					
					{user.work.map((work, i) => (
						<div className = "InfoRectangle " key = {i}>
							<div className = "ExperienceText">
								<div className = "ExperienceDetails">
									<p className = "lobster Position">{work.position}</p>
									<p className = "lobster Company">{work.company}</p>
									{(work.isCurrentRole ? <p className = "lobster Date">{work.start.year}</p> : <p className = "lobster Date">{work.start.year} to {work.end.year}</p>)} 
								</div>
								<p className = "roboto">
									{(
										(work.summary.slice(1)).split("-")).map((item) =>
										<p className = "ExperienceDescription">-{item}</p>
									)}
								</p>
							</div>
						</div>
					))}
					{user.volunteer.map((volunteer, i) => (
						<div className = "InfoRectangle" key = {i}>
							<div className = "ExperienceText">
								<div className = "ExperienceDetails">
									<p className = "lobster Position">{volunteer.position}</p>
									<p className = "lobster Company">{volunteer.organization}</p>
									<p className = "lobster Date">{volunteer.start.year}</p>
								</div>
								<p classname = "roboto">
									{(
										(volunteer.summary.slice(1)).split("-")).map((item) =>
										<p className = "ExperienceDescription">-{item}</p>
									)}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Experience;