import React, { useState, useEffect} from 'react';
import PagesV2 from './pagesV2';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // set username below
    fetch('https://gitconnected.com/v1/portfolio/cefong')
      .then(res => res.json())
      .then(user => {
        setUser(user);
      });
  }, []);

  if (!user) {
    return <div />;
  }
  console.log("should be working");
  return <PagesV2 user={user} />;

}; 

export default App;
