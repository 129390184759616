import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.css';
import tachyons from 'tachyons';

const Sidebar = ({color = "#FFFFFF"}) => {
	return (
		<nav>
			<NavLink className = "NavLink grow" exact to = "/" style = {{color}} activeStyle = {{
				color: "#7a77b9",
				textDecoration: "underline"
			}}> 
				home
			</NavLink>
			<NavLink className = "NavLink grow" to = "/projects" style = {{color}} activeStyle = {{
				color: "#f2c76e",
				textDecoration: "underline"
			}}>
				projects
			</NavLink>
			<NavLink className = "NavLink grow" to = "/experience" style = {{color}} activeStyle = {{
				color: "#ea7186",
				textDecoration: "underline"
			}}>
				experience
			</NavLink>
			<NavLink className = "NavLink grow" to = "/aboutme" style = {{color}} activeStyle = {{
				color: "#ea7186",
				textDecoration: "underline"
			}}>
				about me
			</NavLink>
			<NavLink className = "NavLink grow" to = "/contact" style = {{color}} activeStyle = {{
				color: "#7a77b9",
				textDecoration: "underline"
			}}>
				contact 
			</NavLink>
		</nav>
	);
};

export default Sidebar;
