import React from 'react';
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import tachyons from 'tachyons';
import './styles.css';

const Projects = ({user}) => {
	return (
		<div className = "Projects">
			<Sidebar />
			<div className = "ProjectsContainer">
				<p className = "pacifico ProjectsHeader">projects</p>
				<div className = "TileHolder">					
					{user.projects.map((project, i) => (
						<a href = {"https://github.com/cefong/" + project.name} target = "_blank" rel = "noopener noreferrer" className = {project.name}>
							<div className = "grow InfoRectangle " key = {i}>
								<div className = "ProjectText">
									<p className = "ProjectHeader lobster">{project.name}</p>
									<p className = "ProjectDescription roboto">{project.summary}</p>
									<div className = "SkillContainer">
										{[...project.languages, ...project.libraries].map((item, j) => (
											<p className = "Skill tc"key = {j}>{item}</p>
										))}
									</div>
								</div>
								<div className = "Image">
								</div>
							</div>
						</a>
					))}
				</div>
			</div>
		</div>
	);
};

export default Projects;