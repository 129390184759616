import React from "react";
import Sidebar from "../../components/Sidebar";
import CelineDodgeball from '../../assets/celinedodgeball.JPG';
import Kiki from '../../assets/kiki.jpg';
import Snowboard from '../../assets/snowboard.jpeg';
import './styles.css';
import tachyons from 'tachyons';

const AboutMe = ({user}) => {
	return (
		<div className = "AboutMe">
			<Sidebar />
			<div className = "AboutMeContainer">
				<div className = "AboutMeInfo">
					<p className = "pacifico AboutMeHeader"> about me </p>
					<p className = "AboutMeSummary roboto"> {user.basics.summary} </p>
				</div>
				<div className = "AboutMeProfileSection">
					<div className = "TopPhotos">
						<img src = {CelineDodgeball} alt = "Playing Dodgeball" className = "UpperLeft dib"/>
						<img src = {Kiki} alt = "My Dog!" className = "UpperRight dib"/>
					</div>
					<img src = {Snowboard} alt = "A beautiful day on the mountains" className = "SpanningPhoto"/>
				</div>
			</div>
		</div>
	);
};

export default AboutMe;
